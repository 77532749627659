import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const CreateTestCycle = () => {
  return (
    <LayoutComponent>
      <h1>Create a Test Cycle</h1>

      <p>This task offers an example of how to create a test cycle from the Cycles view and highlights important aspects of the creation process.</p>

      <h2>Steps to Create a Test Cycle</h2>

      <ol>
        <li>Click the <strong>Test Cycles view</strong>.</li>
        {/* <li>In the Folders panel, click the folder where you want to create a new test cycle. If you want to create a new folder, click <strong>Create Folder</strong>, name the new folder, then click the check.</li> */}
        <li>In the Cycles panel, click <strong>+New Test Cycle</strong> to create a new test cycle. The Create Test Cycle screen appears and defaults to the Details tab.</li>
        {/* <li>On the Details tab, fill in the desired fields, including the name of the test cycle (required), then click the <strong>Test Cases</strong> tab.</li> */}
        <li>Fill out all the details</li>
        <li>Click <strong>Save</strong>. The test cycle saves, and a notification appears on the screen and you will be redirected to the test cases tab where you can associate test cases to the test vcycle</li>
      </ol>
      <h3>Fill out the information on the remaining tabs.</h3>
      <ul>
        <li>On the <strong>Details</strong> tab, you can edit the details of the test cycle.</li>
        <li>On the <strong>Test Cases</strong> tab, you can associate test cases to the test cycle.</li>
        <li>On the <strong>Linked Issue</strong> tab, you can see the logged issue during the execution of the test cycle. </li>
        <li>On the <strong>Attachments</strong> tab, you can attach files to the test case.</li>
        <li>On the <strong>Comments</strong> tab, you can add comments to your test cases.</li>
        <li>You can click <strong>Play Icon</strong> to start executing the test cases immediately.</li>
        {/* <li>On the <strong>History</strong> tab, you can track changes done to the test case.</li> */}
      </ul>
    </LayoutComponent>
  )
}

export default CreateTestCycle
